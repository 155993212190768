<template>
    <div id="SalesPlanning">
        <v-container fluid app>
            <ZnapHotGrid
                :options="options"
            >
            </ZnapHotGrid>
        </v-container>
    </div>
</template>

<script>
import Vue from 'vue'
import ZnapHotGrid from '@/components/znap/HotGridEmployeeVacation.vue'

export default {
    name:'SalesPlanning',

    components:{
        ZnapHotGrid
    },

    data: () => ({
        options: [
            {
                column: 'id_event',
                is: 'ComboBox',
                items: [],
                multiple: false,
                label: 'Evento *',
                required: true,
                filterable: false,
                endpoint: [ Vue.prototype.$ipEvent, 'event' ]
            },
            {
                column: 'id_purpose',
                is: 'ComboBox',
                items: [],
                multiple: false,
                label: 'Propósito *',
                required: true,
                filterable: true,
                endpoint: [ Vue.prototype.$ipOrganization, 'purpose' ]
            },
            {
                column: 'payday',
                is: 'ComboBox',
                items: [],
                multiple: false,
                label: 'De/ Até *',
                required: true,
                filterable: false,
            },
            {
                column: 'id_cost_center',
                is: 'ComboBox',
                items: [],
                multiple: false,
                label: 'Centro de custo',
                required: false,
                filterable: true,
                endpoint: [ Vue.prototype.$ipOrganization, 'purpose-cost-center' ],
                conditions: [
                    {
                        AndOr: "AND",
                        column: "id_purpose",
                        operator: "IN",
                        value: null
                    }
                ]
            },
            {
                column: 'id_sales_channel',
                is: 'ComboBox',
                items: [],
                multiple: false,
                label: 'Canal de vendas',
                required: false,
                filterable: true,
                endpoint: [ Vue.prototype.$ipSales, 'sales-channel', 'list-options-planning' ],
                conditions: []
            },
            {
                column: 'id_sales_force',
                is: 'ComboBox',
                items: [],
                multiple: false,
                label: 'Gerente executivo',
                required: true,
                filterable: true,
                endpoint: [ Vue.prototype.$ipSales, 'sales-force', 'list-options-planning' ],
                conditions: []
            },
            {
                column: 'id_sales_force_bp',
                is: 'ComboBox',
                items: [],
                multiple: false,
                label: 'BP',
                required: false,
                filterable: true,
                endpoint: [ Vue.prototype.$ipSales, 'sales-force', 'list-options-planning' ],
                conditions: []
            },
            {
                column: 'id_customer_group',
                is: 'ComboBox',
                items: [],
                multiple: false,
                label: 'Conta cliente',
                required: false,
                filterable: true,
                endpoint: [ Vue.prototype.$ipCustomer, 'customer-group', 'list-options-planning' ],
                conditions: []
            },
            {
                column: 'id_employee',
                is: 'ComboBox',
                items: [],
                multiple: false,
                label: 'Colaborador',
                required: false,
                filterable: true,
                endpoint: [ Vue.prototype.$ipOrganization, 'employee' ],
                conditions: []
            },
            {
                column: 'id_role',
                is: 'ComboBox',
                items: [],
                multiple: false,
                label: 'Cargo',
                required: true,
                filterable: true,
                endpoint: [ Vue.prototype.$ipOrganization, 'role' ],
                conditions: []
            },
        ]
    }),
}
</script>
